import React from "react";
import "./firstpage.css";
import thirdimg1 from "../assets/image 1.png";
import thirdimg2 from "../assets/image 2.png";
import thirdimg3 from "../assets/image 3.png";

const firstpage = () => {
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <p className="label1">Why choose us</p>
      <h1
        style={{
          display: "block",
          flexWrap: "wrap",
          alignItems: "center",
          justifyContent: "center",
          textAlign: "center",
        }}
      >
        Unlock Your Financial Potential
      </h1>
      <p
        style={{
          display: "block",
          flexWrap: "wrap",
          alignItems: "center",
          justifyContent: "center",
          textAlign: "center",
        }}
      >
        Why choose Us for expert guidance and tailored solutions
      </p>
      <div className="content-card">
        <div className="text-section">
          <span className="label">/001</span>
          <h2 className="title" style={{ marginTop: "1rem" }}>
            Personal Credit Repair
          </h2>
          <p className="description">
            Briefly describe how you help individuals fix their credit using
            AI-powered solutions (e.g., automated dispute management, AI-driven
            analysis)
          </p>
          <a href="#" className="btn-contact">
            Contact Us →
          </a>
        </div>
        <div className="image-section">
          <img src={thirdimg1} alt="Soap Bars" />
        </div>
      </div>
      <div className="content-card">
        <div className="image-section">
          <img src={thirdimg2} alt="Soap Bars" />
        </div>
        <div className="text-section">
          <span className="label">/002</span>
          <h2 className="title" style={{ marginTop: "1rem" }}>
            Business Credit Services
          </h2>
          <p className="description">
            Describe how you help businesses build credit, from securing
            business loans to improving credit scores with your AI-driven
            process
          </p>
          <a href="#" className="btn-contact">
            Contact Us →
          </a>
        </div>
      </div>
      <div className="content-card">
        <div className="text-section">
          <span className="label">/003</span>
          <h2 className="title" style={{ marginTop: "1rem" }}>
            LLC Formation
          </h2>
          <p className="description">
            Highlight your fee-free LLC formation service, emphasizing how
            clients only need to pay state taxes
          </p>
          <a href="#" className="btn-contact">
            Contact Us →
          </a>
        </div>
        <div className="image-section">
          <img src={thirdimg3} alt="Soap Bars" />
        </div>
      </div>
    </div>
  );
};

export default firstpage;
