import React, { useState } from "react";

const NotificationBanner = () => {
  const [visible, setVisible] = useState(true);

  const handleClose = () => {
    setVisible(false);
  };

  return (
    visible && (
      <div style={styles.banner}>
        <div style={styles.content}>
          <span style={styles.icon}>✨</span>
          <span>
            Our{" "}
            <a href="#features" style={styles.link}>
              latest features
            </a>{" "}
            are airing now. Explore and enjoy the benefits.
          </span>
        </div>
        <button onClick={handleClose} style={styles.closeButton}>
          ×
        </button>
      </div>
    )
  );
};

const styles = {
  banner: {
    display: "flex",
    alignItems: "center",
    backgroundColor: "rgba(16,185,129,0.31)",
    color: "#333",
    padding: "10px 18px",
    fontSize: "12px",
    position: "relative",
    justifyContent: "center",
  },
  content: {
    display: "flex",
    alignItems: "center",
    gap: "6px",
  },
  icon: {
    marginRight: "0px",
  },
  link: {
    color: "#000000",
    textDecoration: "underline",
  },
  closeButton: {
    position: "absolute",
    right: "20px",
    background: "none",
    border: "none",
    cursor: "pointer",
    fontSize: "24px",
    color: "#000",
  },
};

export default NotificationBanner;
