import React from "react";
import "./thirdpage.css";
import ContactForm from "./ContactForm";

const ThirdPage = () => {
  return (
    <div className="third-page-container">
      <p className="label2">Contact Us</p>
      <h1 className="header-title">Ask anything from our expert</h1>
      <p className="description">
        Ready to elevate your wealth management journey? Contact our team of
        experts or schedule a consultation to learn more about our exclusive
        services.
      </p>
      <ContactForm />
    </div>
  );
};

export default ThirdPage;
