import React from "react";
import "./HeroSection.css";
import img1 from "../assets/Image Placeholder.png";

const HeroSection = () => {
  return (
    <section className="hero-section">
      <div className="hero-content">
        <h1>
          Grow Your Business, and <br />
          Build Your Future with <br />
          <span className="highlight">AI-powered Solutions</span>
        </h1>
        <p>
          Unlock the secrets of your financial behavior and elevate your wealth
          management experience.
        </p>
        <button className="contact-button">
          Contact Us <span>→</span>
        </button>
      </div>
      <div className="hero-image">
        <img src={img1} alt="AI-powered growth graph" />
        <div className="project-info">
          <p>Consultant Project</p>
          <div className="progress-circle">
            <span className="progress-value">95%</span>
          </div>
          <p>Satisfied Customers</p>
        </div>
      </div>
    </section>
  );
};

export default HeroSection;
