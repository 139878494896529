import React from "react";
import { useForm, ValidationError } from "@formspree/react";
import "./ContactForm.css";

function ContactForm() {
  const [state, handleSubmit] = useForm("mqaklokp"); // Replace with your Formspree form ID

  if (state.succeeded) {
    return (
      <p className="success-message">
        Thanks for reaching out! We’ll get back to you shortly.
      </p>
    );
  }

  return (
    <div className="form-container">
      <form onSubmit={handleSubmit} className="contact-form">
        <input
          id="name"
          type="text"
          name="name"
          placeholder="Contact name"
          required
        />
        <input
          id="street"
          type="text"
          name="street"
          placeholder="Street"
          required
        />
        <div className="row">
          <input
            id="city"
            type="text"
            name="city"
            placeholder="City"
            required
          />
          <input
            id="postcode"
            type="text"
            name="postcode"
            placeholder="Postcode"
            required
          />
        </div>
        <input
          id="phone"
          type="tel"
          name="phone"
          placeholder="Contact Phone"
          required
        />
        <input
          id="email"
          type="email"
          name="email"
          placeholder="E-mail"
          required
        />
        <textarea
          id="message"
          name="message"
          placeholder="Let’s talk about your idea"
          rows="4"
          required
        />
        <ValidationError
          prefix="Message"
          field="message"
          errors={state.errors}
        />
        <div className="checkbox-container">
          <input id="terms" type="checkbox" name="terms" required />
          <label htmlFor="terms" className="checkbox-label">
            I accept terms & conditions
          </label>
        </div>
        <button type="submit" disabled={state.submitting}>
          Submit
        </button>
      </form>
    </div>
  );
}

export default ContactForm;
