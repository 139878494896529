import React from "react";
import "./secondpage.css";
import image from "../assets/image.png";

const SecondPage = () => {
  return (
    <div className="ai-container">
      <header className="header">
        <h2>AI CONSULTATION</h2>
        <h1>AI-Driven Financial Solutions</h1>
        <p>What our aim to achieve as a unit</p>
      </header>
      <div className="content">
        <div className="image-container">
          <img src={image} alt="AI Technology" />
        </div>
        <div className="text-container">
          <h2>AI Consultation</h2>
          <p>
            Our advanced AI technology quickly analyzes credit reports, finds
            opportunities for improvement, and automates the dispute process to
            help you achieve a higher score faster.
          </p>
          <a href="#discover-more" className="discover-link">
            Discover More →
          </a>
        </div>
      </div>
    </div>
  );
};

export default SecondPage;
