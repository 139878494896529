import React from "react";
import NotificationBanner from "../Components/NotificationBanner";
import HeroSection from "../pagesfolder/HeroSection";
import Firstpage from "../pagesfolder/firstpage";
import Secondpage from "../pagesfolder/secondpage";
import Thirdpage from "../pagesfolder/thirdpage";
import Footer from "../pagesfolder/Footer";

const Home = () => {
  return (
    <div style={{ marginTop: "5.5rem" }}>
      <NotificationBanner />
      <HeroSection />
      <Firstpage />
      <Secondpage />
      <Thirdpage />
      <Footer />
    </div>
  );
};

export default Home;
